<template>
  <div>
    <div class="todo-container">
      <div class="todo-wrap">
        <MyHeader @addTodo="addTodo" />
        <MyList :todos="todos" />
        <MyFooter
          :todos="todos"
          :checkAllTodo="checkAllTodo"
          :clearAllTodo="clearAllTodo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import pubsub from "pubsub-js";
import MyHeader from "@/components/todo/TodoHeader";
import MyList from "@/components/todo/TodoList";
import MyFooter from "@/components/todo/TodoFooter";
export default {
  name: "ToDo",
  props: [""],
  components: { MyHeader, MyList, MyFooter },
  data() {
    return {
      todos: JSON.parse(localStorage.getItem("todos")) || [],
    };
  },
  methods: {
    addTodo(val) {
      console.log(val);
      this.todos.unshift(val);
    },
    checkTodo(val) {
      this.todos.forEach((todo) => {
        if (todo.id === val) todo.done = !todo.done;
      });
    },
    updateTodo(val, title) {
      this.todos.forEach((todo) => {
        if (todo.id === val) todo.title = title;
      });
    },
    deleteTodo(val) {
      this.todos = this.todos.filter((todo) => {
        return todo.id != val;
      });
    },
    checkAllTodo(val) {
      this.todos.forEach((todo) => {
        todo.done = val;
      });
    },
    clearAllTodo() {
      console.log("clearAllTodo");
      this.todos = this.todos.filter((todo) => {
        return !todo.done;
      });
    },
  },
  watch: {
    todos: {
      deep: true,
      handler(value) {
        localStorage.setItem("todos", JSON.stringify(value));
      },
    },
  },
  mounted() {
    this.$bus.$on("checkTodo", this.checkTodo);
    this.$bus.$on("updateTodo", this.updateTodo);
    this.$bus.$on("deleteTodo", this.deleteTodo);

    this.pubId = pubsub.subscribe("addTodo", function (msgName, payload) {
      console.log("有人发布了消息: ", msgName, payload);
    });
  },
  beforeDestroy() {
    this.$bus.$off("checkTodo");
    this.$bus.$off("updateTodo");
    this.$bus.$off("deleteTodo");
    pubsub.unsubscribe(this.pubId);
  },
};
</script>

<style lang="scss" scoped>
</style>